import { storage } from '@/plugins/firebase'

export const useUpload = (
  file: File,
  path: string,
  setUploading: Function,
  setProgress: Function,
  setDownloadUrl: Function
) => {
  setUploading(true)
  setProgress(0)

  const ref = storage.ref(path)

  const task = ref.put(file)

  const taskStateChange = (snap: any) => {
    const progress = (snap.bytesTransferred / snap.totalBytes) * 100

    setProgress(progress)
  }

  const taskFailed = (error: any) => {
    console.error('Upload failed')
    console.log(error)
  }

  const taskCompleted = async () => {
    const downloadUrl = await task.snapshot.ref.getDownloadURL()

    setDownloadUrl(downloadUrl)
    setUploading(false)
    setProgress(0)
  }

  task.on('state_changed', taskStateChange, taskFailed, taskCompleted)
}
