
import { defineComponent, ref, toRefs, unref } from 'vue'
import { useUpload } from '@/composables/useUpload'
import Swal from "sweetalert2"
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    path: {
      type: String,
      required: true
    },
    downloadUrl: {
        type: String,
        default: undefined
    },
    type: {
      type: String,
      default: 'image',
      validator: (value: string) => ['image', 'video'].indexOf(value) !== -1
    },
    maxSizeMb: {
      type: Number,
      default: 8
    },
    dimensions: {
      type: Object,
      default: () => ({ width: 600, height: 600 }),
      required: false
    }
  },

  emits : [
    'update:downloadUrl'
  ],

  setup (props, { emit }) {
    const { path, type, maxSizeMb, dimensions } = toRefs(props)

    const { t } = useI18n()

    const isUploading = ref<boolean>(false)

    const progress = ref<number>(0)

    const validateType = (file: File) => {
      return unref(type) === file.type.split('/')[0]
    }

    const validateSize = (file: File) => {
      const maxSize = unref(maxSizeMb) * Math.pow(1024, 2)

      return file.size < maxSize
    }

    const validateDimensions =  (file: File, width: number, height: number) => {
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          resolve(img.naturalWidth <= width && img.naturalHeight <= height)
        }
        img.onerror = function () {
          reject(new Error(`not a valid file: ${file.type}`))
        }
        img.src = _URL.createObjectURL(file)
      })
    }

    const handleFileChange = async (e: Event & { target: HTMLInputElement }) => {
      const inputFileElement = e.target
      if (!inputFileElement.files || !inputFileElement.files.length) {
        return
      }
      const file = inputFileElement.files[0]

      const isTypeValid = validateType(file)

      if (!isTypeValid) {
        Swal.fire({
          title: t('state.supported_file_type.title'),
          text: unref(type) === 'image'
            ? t('state.supported_file_type.message_if_image_type')
            : t('state.supported_file_type.message_if_video_type'),
          confirmButtonText: t('state.supported_file_type.action_confirm')
        })

        return
      }

      const isSizeValid = validateSize(file)

      if (!isSizeValid) {
        Swal.fire({
          title: t('state.supported_file_size.title'),
          text: t('state.supported_file_size.message-dynamic', { maxSizeMb: unref(maxSizeMb) }),
          confirmButtonText: t('state.supported_file_size.title')
        })

        return
      }

      if (unref(dimensions) && !!unref(dimensions).width && !!unref(dimensions).height) {
        const isDimensionsValid = await validateDimensions(file, unref(dimensions).width, unref(dimensions).height)
        if (!isDimensionsValid) {
          Swal.fire({
            title: t('state.supported_file_dimension.title'),
            html: t('state.supported_file_dimension.message-dynamic-html', {
              width: unref(dimensions).width,
              height: unref(dimensions).height
            }),
            confirmButtonText: t('state.supported_file_dimension.action_confirm')
          })

          return
        }
      }

      upload(file)
    }

    const upload = (file: File) => {
      const setUploading = (uploading: boolean) => { isUploading.value = uploading }
      const setProgress = (currentProgress: number) => { progress.value = currentProgress }
      const setDownloadUrl = (url: string) => { emit('update:downloadUrl', url) }
      const extension = file.name.split('.')[1]

      useUpload(
        file,
        `${unref(path)}.${extension}`,
        setUploading,
        setProgress,
        setDownloadUrl
      )
    }

    return {
      handleFileChange,
      isUploading,
      progress
    }
  }
})
