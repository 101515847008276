
import { defineComponent, ref, toRaw, unref } from 'vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useProfileService } from '@/services/firestore/profile'
import BaseUploader from '../base/BaseUploader.vue'

export default defineComponent({
  components: { BaseUploader },

  setup () {

    const { profile } = useProfile()

    const { updateProfile } = useProfileService()

    const {
      id,
      user,
      logo = '',
      logoReverse = '',
    } = unref(profile)

    const form = ref({
      id,
      user,
      logo,
      logoReverse
    })

    const basePath = `profile/${user}/${id}`

    const formBuffer = ref({ ...form.value })

    const updateLogoPath = async (downloadUrl: string) => {
      form.value.logo = downloadUrl
      formBuffer.value = { ...form.value }

      await updateProfile(toRaw(form.value))
    }

    const updateReverseLogoPath = async (downloadUrl: string) => {
      form.value.logoReverse = downloadUrl
      formBuffer.value = { ...form.value }

      await updateProfile(toRaw(form.value))
    }

    return {
      form,
      basePath,
      updateLogoPath,
      updateReverseLogoPath
    }
  }
})
